/* eslint-disable import/prefer-default-export */
import { Feature } from '../util/featureFlags';
import { useShopConfig } from './shopConfig';
import { useEnabledCustomerExperiences } from './useEnabledCustomerExperiences';
import { useFeatureFlags } from './useFeatureFlags';

export function useIsShopSideLaunched() {
  const { allowBuy } = useEnabledCustomerExperiences();

  const { shopLaunchDate } = useShopConfig();
  const isForceShopLaunch = useFeatureFlags(Feature.ForceShopLaunch);

  const currentDate = new Date();
  const launchDate = new Date(shopLaunchDate);

  if (isForceShopLaunch || (!shopLaunchDate && allowBuy)) {
    return true;
  }

  const isLaunched = shopLaunchDate && currentDate.getTime() > launchDate.getTime();

  return isLaunched;
}
