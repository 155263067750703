import { Currency } from '../types/apollo/generated/types.generated';
import { Listing } from '../types/sharetribe/listing';
import { convertMoneyToNumber } from '../util/currency';

export const getListingItemAttributes = (listing: Listing) => [
  {
    item_id: listing?.id?.uuid,
    item_name: listing?.attributes?.title,
    item_brand: listing?.attributes?.publicData?.shopName,
    item_category: listing?.attributes?.publicData?.category,
    // Note: this will not correctly get the size for listings that have a
    // special sizeVariantOptionName
    item_variant: listing?.attributes?.publicData?.size,
    price: listing?.attributes?.price?.amount / 100,
    currency: listing?.attributes?.price?.currency || Currency.Usd,
    quantity: 1,
    affiliation: 'Treet',
  },
];

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=16649746610246806356-NC#purchase
 */
export const getGA4PurchasePayload = (listings: Listing[], transactionId: string) => ({
  items: listings.flatMap(getListingItemAttributes),
  value: listings.reduce(
    (value, listing) => value + convertMoneyToNumber(listing.attributes.price),
    0
  ),
  currency:
    listings.reduce<Currency | null>(
      (currency, listing) => currency || listing.attributes.price.currency,
      null
    ) ?? Currency.Usd,
  transaction_id: transactionId,
});

/**
 * https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking/
 */
export const getFBQPurchasePayload = (listings: Listing[]) => ({
  value: listings.reduce(
    (value, listing) => value + convertMoneyToNumber(listing.attributes.price),
    0
  ),
  currency:
    listings.reduce<Currency | null>(
      (currency, listing) => currency || listing.attributes.price.currency,
      null
    ) ?? Currency.Usd,
  contents: listings.map((listing) => ({ id: listing.id.uuid, quantity: 1 })),
  content_ids: listings.map((listing) => listing.id.uuid),
  content_type: 'product',
});
